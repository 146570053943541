<script setup lang="ts">
const props = defineProps({
  error: Object,
})

const handleError = () => clearError({ redirect: '/dashboard' })
</script>

<template>
  <ErrorNotFound
    v-if="error?.statusCode === 404"
    @clearError="handleError"
  ></ErrorNotFound>
  <ErrorGeneric v-else @clearError="handleError"></ErrorGeneric>
</template>
